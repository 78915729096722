.main-banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .sidebar {
        background: #0A0A0A;
        max-width: 244px;
        width: 100%;
        height: 100vh;
        position: sticky;
        top: 0;
        left: 0;

        .upper-logo {
            padding: 40px 32px;
        }

        .bottom-links {

            // position: relative;
            a {
                border-left: 4px solid var(--primary-color, #EAB721);
                background: rgba(234, 183, 33, 0.05);
                padding: 15px 20px;
                color: var(--white, #FFF);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.28px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;
            }

            .connectbtn {
                padding: 0px 20px 28px 20px;
                position: absolute;
                bottom: 0px;

                button {
                    border-radius: 5px;
                    border: 1px solid var(--Primary-Gradient, #F9C924);
                    background: #0A0A0A;
                    box-shadow: 0px 0px 14px 0px rgba(255, 212, 64, 0.20) inset;
                    padding: 15px 25px;
                    width: 100%;
                    color: var(--Primary-Color, #EAB721);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }
        }
    }

    .main-content {
        width: 100%;
        padding: 30px;

        .bottom-cards {
            border-radius: 10px;
            background: #0A0A0A;
            padding: 20px 25px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-content: space-between;
            grid-gap: 15px;

            .card-item {
                border-radius: 8.197px;
                border: 0.82px solid rgba(255, 255, 255, 0.04);
                background: #0A0A0A;
                overflow: hidden;

                .inner-img {
                    width: 100%;
                    height: 237px;
                    overflow: hidden;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .bottom-text {
                    padding: 15px;

                    .card-head {
                        color: #FFF;
                        font-size: 14.754px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;
                        margin-bottom: 30px;
                    }

                    .inner-text {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        p {
                            color: #8D8D8C;
                            font-size: 11.475px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                        }

                        .bg-set-yellow {
                            color: var(--primary-color, #EAB721);
                            font-size: 9.836px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                            border-radius: 57.377px;
                            background: rgba(234, 183, 33, 0.19);
                            padding: 3.28px 8.28px;
                        }

                        .sec-head {

                            color: #8D8D8C;
                            font-size: 11.475px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;

                            span {
                                background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                font-size: 11.475px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }

        .bottom-div {
            border-radius: 10px;
            background: #0A0A0A;
            padding: 45px 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 40px;
            margin-top: 20px;
            margin-bottom: 20px;

            .left-side {
                width: 100%;

                img {
                    width: 404px;
                    height: 375px;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .right-side {
                max-width: 503px;
                width: 100%;

                h6 {
                    color: #FFF;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                    margin-bottom: 15px;
                }

                p {
                    color: var(--light-text, #3E3E3E);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%;
                    /* 25.6px */
                    text-transform: capitalize;
                }

                .twice-text {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 43px;

                    p {
                        color: #8D8D8C;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                    }

                    h6 {
                        color: var(--primary-color, #EAB721);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }
            }
        }
    }

    .nav-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;

        h6 {
            color: var(--white, #FFF);
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 30px */
            text-transform: capitalize;
        }

        button {
            border-radius: 5px;
            background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
            border: none;
            padding: 14px 45px;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 16px */
        }
    }
    .maintable{
        .table-responsive {
            th {
                color:#FFF !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.1px;
                padding: 30px;
                vertical-align: middle;
                border: none;
                white-space: nowrap;
                border-bottom: 1px solid #181818;
                background: #0A0A0A;
            }
    
            td {
                white-space: nowrap;
                padding: 30px;
                vertical-align: middle;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                // border-top: 1px solid rgba(3, 2, 41, 0.05);
                border-bottom: 1px solid #181818;
                background: #0A0A0A;
            }
    
        }
    }
}

.connectmodals {
    .modal-dialog {
        max-width: 652px;

        .modal-content {
            border-radius: 12px;
            border: 1px solid #171717;
            background: #080808;
            backdrop-filter: blur(7.5px);
            padding: 10px;

            .modal-header {
                border-bottom: none;
                border-radius: 8px;
                border: 1px solid var(--stroke, #0C0C0C);
                padding: 12px 10px;
                margin-bottom: 20px;

                .modal-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 100%;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }

                .btn-close {
                    background: url(../../../../src/close-circle.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: contain;
                    width: 26px;
                    height: 26px;
                    box-shadow: none !important;
                    opacity: unset;
                    position: absolute;
                    padding: 0px;
                    margin: 0px;
                    top: 18px;
                    right: 36px;
                    border: none;
                }
            }

            .modal-body {
                .parentss {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 15px;
                    .card {
                        border-radius: 8px;
                        border: 1px solid var(--Stroke, #0C0C0C) !important;
                        padding: 12px 10px;
                        width: 100%;
                        background: #080808;

                        &:hover {
                            border-radius: 10px;
                            background: rgba(234, 183, 33, 0.10);
                        }

                        .innercontent {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            p {
                                color: #FFF;
                                // text-align: center;
                                margin-top: 15px;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (max-width: 991px) {
    .sidebar-mobile-open {
        position: absolute !important;
        transform: translateX(0%) !important;
    }

    .sidebar-mobile-close {
        position: absolute !important;
        transform: translateX(-100%) !important;
    }

    .main-banner .main-content .bottom-cards {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    .main-banner .nav-section h6 {
        font-size: 18px;
    }

    .main-banner .nav-section button {
        padding: 14px;
        font-size: 14px;
    }
}



.image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
.image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
    min-height: 375px;
    object-fit: cover !important;
    border-radius: 10px;
}

.image-gallery-thumbnail {
    width: 133px;
    height: 125px;
    border-radius: 10px;
}

.image-gallery-slide-wrapper.image-gallery-thumbnails-left,
.image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    max-width: 348px;
    width: 100%;
}

.image-gallery-thumbnails {
    overflow: visible;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    width: 100%;
    height: 100%;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    display: none;
}

.image-gallery-play-button {
    display: none;
}

.image-gallery-fullscreen-button {
    display: none;
}

@media (max-width:600px) {
    .main-banner .main-content .bottom-div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }

    .image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
    .image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
        min-height: 215px;
    }

    .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
    .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
        max-width: 100%;
        width: calc(100% - 87px);
    }

    .image-gallery-thumbnail {
        width: 76px;
        height: 72px;
    }
}