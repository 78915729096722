.add-property {
    width: 100%;
    padding: 30px;
position: relative;
    .option-field {
        position: relative;
        margin-bottom: 20px;

        input {
            border-radius: 5px;
            border: 1.5px solid var(--stroke, #0C0C0C);
            background: #020202;
            padding: 18px 20px;
            color: var(--field-text, #fff);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            /* 20.8px */
            width: 100%;

            &::placeholder {
                color: var(--field-text, #fff) !important;
            }
        }

        textarea {
            border-radius: 5px;
            border: 1.5px solid var(--stroke, #0C0C0C);
            background: #020202;
            padding: 18px 20px;
            color: var(--field-text, #fff);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            /* 20.8px */
            width: 100%;
            height: 142px;

            &::placeholder {
                color: var(--field-text, #fff) !important;
            }
        }
    }

    .upload-img {
        h6 {
            color: var(--white, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 13px;
        }

        .upload {
            width: 100%;
            height: 264px;
            overflow: hidden;
            border-radius: 10px;
            border: 1px dashed var(--stroke, #0C0C0C);
            background: #0B0B0B;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .after-upload {
        margin-top: 13px;
        border-radius: 10px;
        border: 1px solid var(--stroke, #0C0C0C);
        background: #020202;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 15px;

        .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            .imgs {
                width: 72px;
                height: 54px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }

            .inner-text {
                h6 {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 170%;

                }

                p {
                    color: var(--side-baar-text-color, #797979);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 170%;

                }
            }
        }

        &.process-video {
            .left {
                opacity: 0.2;
            }
        }
    }

    .savebtn {
     
        button {
            border-radius: 5px;
            background: var(--Primary-Gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
            padding: 15px 98px;
            width: 100%;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            border: none;
        }
    }
}


@media (max-width:991px) {
    .order-2sm {
        order: 2;
    }

    .order-1sm {
        order: 1;
        margin-bottom: 40px;
    }
}