@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  padding: 0px;
  margin: 0px;
  background-color: #000;
}

.forcflexcenter {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

iframe {
  display: none !important;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

@media (max-width: 600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;
}

// calnder range picker scss here......................
.rs-picker-menu {
  top: 243.05px !important;
  right: 66px !important;
  left: unset !important;
  background: #FFFFFF !important;
  border: 1px solid #E3E3E3 !important;
  border-radius: 5px !important;
  box-shadow: unset !important;
}

.rs-picker-daterange-header {
  display: none !important;
}

.rs-calendar-table-cell-content {
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px !important;
  // letter-spacing: 0.03em;
  color: #2D3436;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  background: #FF0083 !important;
  border-radius: 7px !important;
  border: none !important;
  box-shadow: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  // letter-spacing: 0.03em;
  color: #FFFFFF;
}

.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
  background: #FF0083;
  border-radius: 8px;
}

.rs-btn-primary {
  background: #FF0083;
  border-radius: 8px;
}

.rs-picker-toggle {
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
}

.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #181818;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: #FF0083 !important;
  border-radius: 7px !important;
  border: none !important;
  box-shadow: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  // letter-spacing: 0.03em;
  color: #FFFFFF;
}

.rs-calendar-table-cell-content:hover {
  background: #FF0083 !important;
  border-radius: 7px !important;
  border: none !important;
  box-shadow: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  // letter-spacing: 0.03em;
  color: #FFFFFF;
}

.rs-calendar-table-cell-in-range::before {
  background: #FFF7FB;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  color: #fff;
}

.rs-btn-subtle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2D3436;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #181818;
}

.rs-btn-link {
  color: #ff0083;
}

@media (max-width:600px) {
  .rs-picker-daterange-calendar-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
  }

  .rs-picker-menu {
    top: 300px !important;
    right: 30px !important;
  }

  .rs-picker-menu .rs-calendar {
    min-width: 300px !important;
    margin: 0 auto;
  }

  .rs-picker-daterange-calendar-group {
    min-width: 300px;
  }

  .rs-picker-daterange-menu .rs-calendar:first-child {
    border: none !important;
  }
}


/* Remove this container when use*/
.component-title {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #888;
  text-align: center;
}

/* The switch - the box around the slider */
.fytgyugy {
  width: 51px;
  height: 31px;
  position: relative;
}

/* Hide default HTML checkbox */
.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.switch {
  width: 44.003px;
  height: 24px;
  padding: 2px;
  display: block;
  background-color: #B2BEC3;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

/* The slider */
.slider {
  width: 20px;
  height: 20px;
  position: absolute;
  left: calc(50% - 27px/2 - 10px);
  top: calc(50% - 27px/2);
  background: #FFFFFF;
  border-radius: 77px;
  box-shadow: 0px 2px 4px 0px rgba(0, 35, 11, 0.20);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox:checked+.switch {
  background: #FF0083;
}

.checkbox:checked+.switch .slider {
  left: calc(50% - 27px/2 + 10px);
  top: calc(50% - 27px/2);
}

@media (max-width:600px) {
  .switch {
    width: 100%;
  }

  .checkbox:checked+.switch .slider {
    left: calc(50% - 6.5px + 10px);
  }
}

// .customsss-container {
//   max-width: 1380px;
//   padding: 0px 20px;
//   width: 100%;
//   margin: 0 auto;
// }


.profilemodal {
  .modal-dialog {
    max-width: 524px;

    .modal-content {
      border-radius: 5px;
      border: 4px solid var(--stroke, #0C0C0C) !important;
      background: #060606;
      box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
      padding: 30px;

      .modal-header {
        border-bottom: none;
        border-radius: 8px;
        border: 1px solid var(--stroke, #0C0C0C);
        padding: 12px 10px;
        margin-bottom: 20px;

        .modal-title {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 100%;
          text-transform: uppercase;
          color: #FFFFFF;
        }

        // .btn-close {
        //     background: url(../../../src/Assests/close-circlemodal.svg);
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     background-position: contain;
        //     width: 22px;
        //     height: 22px;
        //     box-shadow: none !important;
        //     opacity: unset;
        //     position: absolute;
        //     padding: 0px;
        //     margin: 0px;
        //     top: 40px;
        //     right: 45px;
        // }
      }

      .modal-body {
        .parenttick {
          .images {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .text {
            margin-top: 20px;
            margin-bottom: 50px;

            p {
              color: var(--secondary-white, #FFF);
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;

              span {
                color: var(--Primary-Color, #EAB721);

                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
              }
            }
          }

          .okaybtn {
            button {
              border-radius: 5px;
              background: var(--Primary-Gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
              padding: 14px 81px;
              width: 100%;
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              border: none;
            }

          }
        }

        .cardprogress {
          display: flex;
          gap: 8px;
          flex-direction: column;
          width: 100%;
          margin-top: 40px;
          margin-bottom: 15px;

          .progress {
            border-radius: 50px;
            border: 1px solid #171717;
            background: #0B0B0B;
            height: 15px;

            .progress-bar {

              border-radius: 50px;
              background: var(--Primary-Color, #EAB721);
            }
          }

          .cardprogressheadings {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 17px;

            .cardprogresshead {
              color: #FFF;

              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
            }

            .cardprogresspara {
              color: #717171;

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
            }
          }
        }

        .para {
          color: #FFF;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0.1px;
          margin-bottom: 15px;
        }

        .wrapper {
          border-radius: 8px;
          border: 3px dashed var(--stroke, #0C0C0C);
          background: #060606;
          padding: 78px 15px;
          width: 100%;
          text-align: center;
        }

        .endbtn {
          button {
            border-radius: 5px;
            background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
            padding: 14px 81px;
            width: 100%;
            border: none;
            margin-top: 50px;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .parentinputs {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 19px;
          margin-top: 10px;

          .code {
            border-radius: 8px;

            border-radius: 8px;
            border: 1px solid var(--primary-color, #EAB721);
            background: #060606;
            padding: 20px 17.833px 19px 18px;
            // width: 57.83px;
            // height: 76px;
            width: 100%;
            color: var(--primary-color, #EAB721);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--primary-color, #EAB721);
            font-size: 34px;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
            text-align: center;

            &:focus {
              border: 1px solid (--primary-color, #EAB721);
            }

            &::placeholder {
              color: var(--primary-color, #EAB721);
              text-align: center;
              font-size: 34px;
              font-style: normal;
              font-weight: 700;
              line-height: 110%;
              text-transform: uppercase;
            }
          }



        }

        .digitbtn {
          button {
            border-radius: 5px;
            background: rgba(234, 235, 240, 0.04);
            padding: 18px 45px;
            width: 100%;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            border: none;
            margin-top: 50px;
          }
        }

        .mainimage {
          width: 206px;
          height: 206px;
          object-fit: cover;
          object-position: center;
          margin: 0 auto;

          .success {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .disabletext {
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            color: #FFF;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
          }
        }

        input {
          border-radius: 5px;
          border: 1px solid var(--stroke, #0C0C0C);
          background: #060606;
          padding: 18px 15px;
          width: 100%;
          color: #FFF;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 14px */
          letter-spacing: 0.1px;

          &::placeholder {
            color: var(--field-text, #3E3E3E);
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 14px */
            letter-spacing: 0.1px;
          }
        }

        .blance-image {
          width: 184px;
          height: 184px;
          object-fit: cover;
          object-position: center;
          margin: 0 auto;

          .innerblnc {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .paraemail {
          p {
            color: var(--white, #FFF);
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            margin-top: 10px;
            margin-bottom: 50px;
          }
        }

        .next {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
          margin-top: 25px;

          button {
            border-radius: 5px;
            background: var(--primary-gradient, linear-gradient(90deg, #F9C924 0%, #FFF98C 26.52%, #E4AF18 73.21%, #FFD440 100%));
            padding: 14px 38px;
            width: 100%;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
          }

          .backbtn {
            padding: 14px 38px;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: 100%;
            border: 1px solid rgba(255, 212, 64, 0.20) !important;
            position: relative;
            border: 1px solid transparent;
            background: #000;
            background-clip: padding-box;
            border-radius: 5px;
          }


        }

        .firstinput {
          margin-bottom: 24px;
        }

        .emailinput {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 5px;
          border: 1px solid var(--stroke, #0C0C0C);
          background: #060606;
          padding: 18px 15px;

          h6 {
            color: var(--white, #FFF);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 14px */
            letter-spacing: 0.1px;
            padding-right: 10px;
            border-right: 1px solid #A9A8A8;
            white-space: nowrap;
          }

          input {
            background-color: transparent;
            border: none;
            padding: 0;
            padding-left: 10px;
            color: var(--field-text, #FFF);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 14px */
            letter-spacing: 0.1px;
            width: 100%;

            &::placeholder {
              color: #3E3E3E;
            }
          }
        }

        .parentinputmodal {
          position: relative;

          .emailinput {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 5px;
            border: 1px solid var(--stroke, #0C0C0C);
            background: #060606;
            padding: 18px 15px;

            h6 {
              color: var(--white, #FFF);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 14px */
              letter-spacing: 0.1px;
              padding-right: 10px;
              border-right: 1px solid #A9A8A8;
              white-space: nowrap;
            }

            input {
              background-color: transparent;
              border: none;
              padding: 0;
              padding-left: 10px;
              color: #3E3E3E;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 14px */
              letter-spacing: 0.1px;
              width: 100%;

              &::placeholder {
                color: #3E3E3E;
              }
            }
          }

          .gold {
            position: absolute;
            top: 20px;
            right: 20px;
            color: var(--primary-color, #EAB721);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 14px */
            letter-spacing: 0.1px;
          }

          .paratext {
            color: var(--light-text, #3E3E3E);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 12px */
            letter-spacing: 0.1px;
            margin-top: 12px;
            margin-bottom: 25px;
          }


        }

        .security {
          color: var(--primary-color, #EAB721);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 14px */
          letter-spacing: 0.1px;
          margin-bottom: 25px;
        }

        .successfully {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .mainimagesss {
            width: 206px;
            height: 206px;
            object-fit: cover;
            object-position: center;

            .done {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .innertextsss {
            h6 {
              color: var(--white, #FFF);
              text-align: center;
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: 110%;
            }

            p {
              color: var(--light-text, #3E3E3E);
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 12px */
              letter-spacing: 0.1px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}